import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from '../assets/img/logo-mi-retrato.png';
import LogoManper from '../assets/img/logo-vanessa-manper.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTwitter, faPinterestP } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

// TODO: Redes sociales dinamicas?
// TODO: Logo dinamoco?

const icons = {
  facebook: faFacebookF,
  twitter: faTwitter,
  instagram: faInstagram,
  pinterest: faPinterestP
}

const Nav = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName,
          social {
            facebook
            twitter
            instagram
            pinterest
          }
        }
      }
    `}
    render={(data) => (
      <header className="mr-header">
        <Link to="/" className="mr-brand">
          <img src={process.env.SITE_URL === 'vanessamanper.cl' ? LogoManper : Logo} alt={data.strapiGlobal.siteName}/>
        </Link>
        <ul className="mr-navbar">
          {
            Object.keys(data.strapiGlobal.social).filter(key => (
              (data.strapiGlobal.social[key] || '').trim().length
            )).map(key => (
              <li key={key}>
                <a target="_blank" rel="noreferrer" href={data.strapiGlobal.social[key]} data-toggle="tooltip" data-placement="top" title={key}>
                  <FontAwesomeIcon icon={icons[key]}/>
                </a>
              </li>
            ))
          }
          <li>
            <a href="/contacto" data-toggle="tooltip" data-placement="top" title="Contacto">
              <FontAwesomeIcon icon={faEnvelope}/>
            </a>
          </li>
        </ul>
      </header>
    )}
  />
);

export default Nav;
